.footer{
    background-color:#eeeeee;
    position: fixed;
    bottom: 0;
    clear: both;
    width: 100%;
    height: 40px;
    
  
}

.parrafo{
    text-align: center;
    font-size: 2 em;
    color: black;
    padding: 0.75%;
    font-weight: bolder;

}